<template>
  <div class="row">
    <div
      v-for="(card, index) in data"
      :key="index"
      class="d-flex align-items-stretch "
      :class="{
        'col-md-3': data.length > 3,
        'col-md-4': data.length === 3,
        'col-md-6': data.length === 2,
        'col-md-12': data.length === 1,
      }"
    >
      <div class="card w-100">
        <div class="d-flex justify-content-center align-items-center h-100">
          <img
            v-if="card.image_url"
            v-image-preview
            class="card-img-top"
            :src="card.image_url"
            :class="{
              'fixed-width': data.length <= 2,
            }"
            style="margin: 0 auto; padding: 4px"
          >
        </div>
        <div class="card-body">
          <WordWithPopover
            :element-class="['card-title mb-0 text-center']"
            tag="h5"
            :text="`${(isAProblem ? formatQuestion(card.title,'title',index): card.title) || ''}`"
            :word-power-enabled="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { checkSettings } from '@/utils/visibilitySettings'
import WordWithPopover from '@/views/common/templates/components/WordWithPopover.vue'

export default {
  components: {
    WordWithPopover,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    placeHolder: {
      type: String,
      default: '',
    },
    isAProblem: {
      type: Boolean,
      default: false,
    },
    questionFormatter: {
      type: Function,
      default: null,
    },
  },
  computed: {
    copyPasteEnabled() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      const setting = checkSettings('copy_paste', schoolSetting, distSetting)
      if (setting.value === '1') {
        return true
      }
      return false
    },
  },
  methods: {
    formatQuestion(question, dataString, ind) {
      if (this.questionFormatter && typeof this.questionFormatter === 'function') {
        return this.questionFormatter(question, dataString, ind)
      }
      let html = ''
      let index = 0
      question
        .replaceAll(this.placeHolder, `$${this.placeHolder}$`)
        .split('$')
        .filter(e => e.length > 0)
        .forEach(element => {
          if (element === this.placeHolder) {
            this.$emit('createAnswer', `${dataString}-${ind}-${index}`)
            html += `
              <input
                type="text"
                :id="'Field_' + ${(index + 1)}"
                data-id="${dataString}-${ind}-${index}"
                style="display: inline-block; width: 150px;margin-top:2px"
                class="mx-1 answer form-control"
                :name="'Field_' + ${(index + 1)}"
                onpaste="return ${this.copyPasteEnabled};"
              />
            `
            index += 1
          } else {
            html += element
          }
        })
      return html
    },
  },
}
</script>
<style scoped>
@media (min-width: 992px) {
  .fixed-width {
    max-width: 350px !important;
  }
}
</style>
