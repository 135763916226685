<template>
  <!-- basic modal -->
  <b-row>

    <!-- Question title/hint -->
    <b-col
      class="problem-card-title"
      md="12"
    >
      <h4 class="mb-0">
        {{ problemDescription || getProblemHintsDescription($t("student-test-module.multi-problem-hint")) }}
      </h4>
    </b-col>
    <!-- Question title/hint -->
    <b-col
      v-if="getExtraImage"
      md="12"
      class="problem-card-mid-content"
    >
      <div class="rounded-top text-center">
        <b-img
          v-image-preview
          :src="getExtraImage"
          fluid
        />
        <div style="text-align: center;">
          <span
            v-if="getExtraCaption"
            class="ml-2 img-caption"
          >{{ getExtraCaption }}</span>
        </div>
      </div>
    </b-col>
    <!-- Question Image -->
    <b-col
      v-else-if="localQuestion.data_type ==='image'"
      md="12"
      class="problem-card-mid-content"
      :set="caption = getCaption(localQuestion)"
    >
      <div class="rounded-top text-center">
        <b-img
          v-image-preview
          :src="localQuestion.file"
          fluid
        />
        <div style="text-align: center;">
          <span
            v-if="caption"
            class="ml-2 img-caption"
          >{{ caption }}</span>
        </div>
      </div>
    </b-col>
    <!--decoration image-->
    <b-col v-else-if="decorationImage"
           class="problem-card-mid-content"
           md="12"
    >
      <div class="rounded-top text-center">
        <b-img
          v-image-preview
          :src="decorationImage"
          fluid
        />
      </div>
    </b-col>
    <!-- Question Image -->

    <!-- Question Audio -->
    <b-col
      v-if="localQuestion.data_type ==='audio'"
      class="problem-card-mid-content"
      md="12"
    >
      <audio-player
        :src="localQuestion.file"
      />
    </b-col>
    <!-- Question Audio -->

    <!-- Question Text-->
    <b-col
      v-if="localQuestion.data_type ==='text'"
      class="problem-card-mid-content"
      md="12"
    >
      <h4 v-text-to-speech
          v-html="localQuestion.text"
      />
    </b-col>
    <!-- Question Text-->

    <!-- Questions-->
    <b-col
      md="12"
      sm="12"
      class="problem-overview-content-bg"
    >
      <b-row>
        <b-col
          v-for="(answer,index) in answers"
          :key="'answer_'+index"
          :lg="answerCluster || (answer.data_type === 'text' ? '12' : '6')"
          md="12"
          sm="12"
          class="problem-overview-content spoc d-flex align-items-center multiple-choice-question"
          :set="caption = getCaption(answer)"
        >

          <span
            class="mr-1"
          >
            {{ index+1 }}
          </span>

          <input
            v-show="answer.data_type !== 'text'"
            v-model="selectedAnswers"
            type="checkbox"
            :value="answer.id"
          >

          <div
            v-if="answer.data_type==='image'"
            class="rounded-top text-center ml-1"
            @click="toggleSelect(answer.id)"
          >
            <figure>
              <b-img
                :src="answer.file"
                fluid
              />
              <figcaption
                v-if="caption"
                class="mt-1 text-center text-white"
              >
                {{ caption }}
              </figcaption>
            </figure>
          </div>

          <b-button
            v-else-if="answer.data_type=='text'"
            v-text-to-speech
            class="ml-1 mb-0"
            style="color: white !important;"
            :variant="selectedAnswers.includes(answer.id) ? 'warning' : 'secondary'"
            @click="toggleSelect(answer.id)"
            v-html="answer.text"
          />

          <div
            v-if="answer.data_type==='audio'"
            class="audio-controls"
            @click="toggleSelect(answer.id)"
          >
            <audio-player
              v-if="answer.data_type==='audio'"
              :alignment="'left'"
              :src="answer.file"
            />
          </div>

        </b-col>
      </b-row>
    </b-col>
    <portal
      to="problemFeedBack"
    >
      <b-row>
        <b-col md="12"
               class="p-0"
        >
          <feedback
            :feedback="feedback"
            :problem-statement-id="problemStatementId"
          />
        </b-col>
        <b-col
          v-if="!isPreview"
          class="m-0 p-0"
          md="12"
        >
          <actions
            :is-processing="isProcessing"
            :level="level ? level : ''"
            :on-submit="submit"
            :disabled="selectedAnswers.length==0"
            :has-minimal-view="hasMinimalView"
            :has-feedback="hasFeedback"
            :tooltip-text="$t('student-test-module.multiple-problem-validation-message')"
            :display-result="displayResult"
            :button-title="buttonTitle || (hasFeedback ? $t('next') : $t('submit'))"
            :engine="engine"
            :school-settings="schoolSettings"
            :district-settings="districtSettings"
            v-on="$listeners"
          />
        </b-col>
      </b-row>
    </portal>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BImg, BButton,
} from 'bootstrap-vue'
import AudioPlayer from '@/views/common/components/AudioPlayer.vue'
import Actions from './Actions.vue'
import Feedback from './Feedback.vue'

export default ({
  components: {
    BRow, BCol, Actions, Feedback, BImg, AudioPlayer, BButton,
  },
  props: {
    questions: {
      type: Array,
      required: true,
    },
    problemDescription: {
      type: String,
      default: '',
    },
    buttonTitle: {
      type: String,
      default: '',
    },
    previewAnswers: {
      type: Array,
      default: () => [],
    },
    isProcessing: {
      type: Boolean,
      required: true,
    },
    feedback: {
      type: Object,
      default: () => {},
    },
    displayResult: {
      type: Boolean,
      default: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      required: true,
    },
    level: {
      type: Number,
      default: 1,
    },
    problemStatementId: {
      type: [Number, String],
      required: false,
    },
    startTime: {
      type: [String, Date],
      required: false,
      default: () => null,
    },
    problemGroupType: {
      type: String,
      required: false,
      default: () => '',
    },
    engine: {
      type: String,
      default: () => '',
    },
    rules: {
      type: [Object, Array],
      default: () => null,
    },
    hasMinimalView: {
      type: Boolean,
      default: false,
    },
    schoolSettings: {
      type: Array,
      default: () => [],
    },
    districtSettings: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localQuestion: [],
      answers: [],
      selectedAnswers: [],
    }
  },
  computed: {
    hasFeedback() {
      return !!(this.feedback?.text?.length > 0 || this.feedback?.image || this.feedback?.audio || this.feedback?.template)
    },
    answerCluster() {
      return this.$store.getters['problem/getAnswerCluster']
    },
    isSingleChoice() {
      return this.rules?.choice_type === 'single'
    },
    decorationImage() {
      const { activeProblem } = this.$store.state.problem
      return activeProblem?.file
    },
    getExtraImage() {
      return this.JSON_PARSE(this.localQuestion.extra_file)?.extra_image || null
    },
    getExtraCaption() {
      return this.JSON_PARSE(this.localQuestion.extra_file)?.extra_caption || null
    },
  },
  watch: {
    questions() {
      this.setInitialData()
    },
  },
  created() {
    this.setInitialData()
    if (this.previewAnswers && this.previewAnswers.length) {
      this.previewAnswers.forEach(ans => {
        this.selectedAnswers.push(parseInt(ans.input, 10))
      })
    }
  },
  methods: {
    getCaption(item) {
      return this.JSON_PARSE(item.extra_file)?.caption || null
    },
    toggleSelect(id) {
      if (this.isSingleChoice) {
        this.selectedAnswers = []
      }
      const index = this.selectedAnswers.findIndex(obj => obj === id)

      if (index >= 0) this.selectedAnswers.splice(index, 1)
      else this.selectedAnswers.push(id)
    },
    setInitialData() {
      this.localQuestion = JSON.parse(JSON.stringify(this.questions[0]))
      this.answers = JSON.parse(JSON.stringify(this.localQuestion.answers))
      this.answers.sort(() => Math.random() - 0.5)
      const previewInputs = this.previewAnswers.map(item => item.input)
      this.answers.forEach(e => {
        // eslint-disable-next-line eqeqeq
        e.is_checked = !!previewInputs.includes(item => item == e.id)
      })
      this.selectedAnswers = []
    },
    submit() {
      this.$emit('submitQuestion', { lang_level: this.localQuestion.lang_level, answer: this.selectedAnswers })
    },
  },
})
</script>

<style lang="css" scoped>
.checkbox{
  display: inline-block;
  height: 100%;
}
.custom-control-label::after{
  top: 10px !important;
}
input[type='checkbox']{
  flex-shrink: 0;
  height: 20px;
  width: 20px;
  border-radius: 4px;
}
.audio-controls{
  flex:1;
}
.audio-controls audio{
  width:100% ;
}
.problem-overview-content img{
  height: 150px;
  width: auto;
}

.multiple-choice-question{
  border-color: white;
  border-style: solid;
  border-width: 0px;
}
.multiple-choice-question:nth-child(even){
  border-left-width: 1px;
  border-bottom-width: 1px;
}
.multiple-choice-question:nth-child(odd){
  border-bottom-width: 1px;
}
</style>
