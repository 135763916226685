<template>
  <b-row class="problem-card-footer m-0 w-100">
    <b-col md="12">
      <b-row>
        <b-col
          md="6"
          class="d-flex align-items-center"
        >
          <template v-if="!hasMinimalView && !hideRTI">
            <div v-if="!enginesToExcludeLevel.includes(engine)">
              <span class="student-stats-title mr-1">{{
                $t("student-test-module.level")
              }}</span><level-indicator :level="level" />
            </div>
            <div v-if="!attentionSetting">
              <span class="student-stats-title ml-1">{{
                $t("student-test-module.attention")
              }}</span>
              <div
                class="progress ml-1"
                style="
                height: 10px;
                min-width: 100px;
                color: rgb(229, 71, 50);
                border: 1px white solid;
              "
              >
                <div
                  role="progressbar"
                  :style="`background-color:${getStudentProgressBarColor(
                    studentAttention
                  )};width:${studentAttention}%`"
                  class="progress-bar"
                />
              </div>
            </div>
          </template>
        </b-col>
        <b-col
          v-if="actionVisible"
          md="6"
          class="d-flex mt-2 mt-md-0 justify-content-end"
        >
          <b-button v-if="canAbortLesson"
                    variant="danger"
                    size="md"
                    role="button"
                    class="mr-2"
                    @click="confirmAbort"
          >
            {{ $t('labels.abort') }}
          </b-button>
          <div v-b-tooltip.hover.top="disabled ? tooltipText : ''">
            <b-button
              variant="success"
              size="md"
              :disabled="(disabled || isProcessing || isSubmitting) && !autoPlay"
              @click="handleSubmit"
            >
              <b-spinner
                v-show="isProcessing || isSubmitting"
                small
              />
              {{ buttonTitle }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BButton, BSpinner, VBTooltip,
} from 'bootstrap-vue'
import LevelIndicator from '@/views/common/components/LevelIndicator.vue'
import { getStudentProgressBarColor } from '@/utils/colors'
import { mapGetters } from 'vuex'
import {
  checkSettings, isVisible,
} from '@/utils/visibilitySettings'
import GEC_EVENT_BUS from '@/utils/eventBus'
// import i18n from '@/libs/i18n'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BButton,
    BSpinner,
    LevelIndicator,
  },
  inject: ['handleLessonAbort', 'getFetchingProblemInfo'],
  props: {
    onSubmit: {
      type: Function,
      required: true,
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    displayResult: {
      type: Boolean,
      default: false,
    },
    hasFeedback: {
      type: Boolean,
      default: false,
    },
    tooltipText: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'question',
    },
    level: {
      type: Number,
      default: 1,
    },
    canSkipStatement: {
      type: Boolean,
      default: false,
    },
    actionVisible: {
      type: Boolean,
      default: true,
    },
    buttonTitle: {
      type: String,
      default: () => '',
    },
    engine: {
      type: String,
      default: () => '',
    },
    hasMinimalView: {
      type: Boolean,
      default: false,
    },
    schoolSettings: {
      type: Array,
      default: () => [],
    },
    districtSettings: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      enginesToExcludeLevel: ['linearStandAloneEngine', 'linearstandaloneengine'],
      // hideRTI: true,
      // attentionSetting: true,
    }
  },
  computed: {
    ...mapGetters({
      studentAttention: 'studentInteractions/currentStudentAttention',
    }),
    canAbortLesson() {
      return !this.$store.getters['studentLab/isAClassTest'] && this.$store.getters['studentLab/canAbortLesson']
    },
    isSubmitting() {
      return this.$store.state.problem.isSubmittingQuestion
    },
    classId() {
      return Number(this.$route.params.classId || 0)
    },
    autoPlay() {
      return this.$route.query.autoPlay || false
    },
    // buttonTitle() {
    //   if (this.mode === 'statement') return i18n.tc('actions.proceed-to-question')
    //   if (this.displayResult) return i18n.tc('student-test-module.view-result')
    //   if (this.hasFeedback) return i18n.tc('student-test-module.next-problem')
    //   return i18n.tc('submit')
    // },
    attentionSetting() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      return !isVisible('attention', schoolSetting, distSetting)
    },
    hideRTI() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      return !isVisible('rti', schoolSetting, distSetting)
    },
  },
  mounted() {
    // this.getClassRoomSettings()
    if (this.autoPlay) {
      GEC_EVENT_BUS.$on('submitQuestion', () => {
        this.handleSubmit()
        setTimeout(() => {
          this.$emit('nextQuestion')
        }, 3000)
      })
    }
    this.storeSettings()
  },
  methods: {
    storeSettings() {
      if (this.schoolSettings?.length > 0) {
        const keys = ['fancy_audio_player', 'open_ai', 'game', 'avatar', 'attention']
        const aiKeys = ['has_chat_bot', 'ai_speech', 'ai_feedback']

        let settings = this.schoolSettings
        const newSettings = checkSettings(keys, this.schoolSettings, this.districtSettings, { keyLabel: 'setting_name' })
        const formattedSettings = []

        Object.entries(newSettings).forEach(([key, value]) => {
          formattedSettings.push({
            setting_name: key,
            value,
          })
        })

        // remove default classroom settings
        settings = settings.filter(setting => !keys.includes(setting?.setting_name))
        // add new settings after checking district setting
        settings = [
          ...settings,
          ...formattedSettings,
        ]

        // remove openAI settings from classroom settings if disabled from district
        const openAI = formattedSettings.find(s => s.setting_name === 'open_ai')
        if (!Number(openAI.value)) {
          settings = settings.filter(setting => !aiKeys.includes(setting?.setting_name))
        }

        this.$store.dispatch('session/setClassSettings', { settings, selfStudyClassRoom: false })
      }
    },
    getStudentProgressBarColor(value) {
      return getStudentProgressBarColor(value)
    },
    handleSubmit() {
      const synth = window.speechSynthesis
      if (synth && synth.speaking) {
        synth.cancel()
      }
      if (this.hasFeedback) {
        this.$emit('nextQuestion')
      } else {
        this.onSubmit()
      }
      // TODO: fix fancy event from beforeDestroy
      this.$root.$emit('removeFancyEvent')
    },
    confirmAbort() {
      this.$swal.fire({
        title: this.$t('abort.title'),
        text: this.$t('abort.lesson-abort-text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: this.$t('labels.cancel'),
        confirmButtonText: this.$t('abort.confirm'),
      }).then(result => {
        if (result.isConfirmed) {
          this.handleLessonAbort()
        }
      })
    },
  },
}
</script>
